#page-topbar {
  &.dc-layout {
    background-color: $primary !important;
    height: 70px;
    max-height: 70px;

    @media (min-width: 1200px) {
      margin: auto;
      max-width: 576px;
    }
  }
}

.dc-header-notifications-btn {
  width: fit-content;
  height: 100%;
  padding: 0px;

  i {
    color: $white !important;
  }
}

.dc-header-btns-row {
  padding-right: 24px;
}

.dc-header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $white !important;
  border: 0;
  border-radius: 0px;
}
