// .new-layout-login-page-logo-row {
//   // height: 20vh;
// }

.new-layout-auth-logo {
  width: 120px;
  height: 45.55px;

  // margin-left: 24px;
}

.new-layout-login-btn-div {
  margin-top: 40px;
  margin-bottom: 40px;
}

.new-layout-login-card {
  max-width: 550px;
  // max-height: 530px;
  margin: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 1199px) {
    box-shadow: none;
  }
}

.new-layout-auth {
  background-color: $new-layout-bg-color;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;

  @media (max-width: 1199px) {
    background-color: $white;
  }

  .new-layout-container {
    width: 100%;
    // height: 100vh;
    // position: absolute;
    top: 35vh;
    // right: 25%;
  }

  &.register {
    height: 125vh;
    .new-layout-container {
      top: 37.5vh;
    }
  }
}

.new-layout-auth-form-group {
  .form-group {
    label {
      float: left;

      font-size: 14px;
      font-weight: 500;

      color: #6a7187;
    }
  }
}

.new-layout-forgot-password {
  float: right;
  margin-bottom: 24px;

  font-size: 12px;
  font-weight: 400;
  color: #2544b1;
}

.new-layout-auth-form {
  width: 90%;
}

.new-layout-auth-welcome-msg {
  font-size: 20px;

  width: 208px;
  height: 60px;

  color: $gray-700;

  &.register {
    margin-bottom: 36px;
  }
}

.new-layout-logo-col {
  width: 100vw;
  // height: 20vh;
  // position: absolute;
  top: 5vh;

  display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  img {
    margin-bottom: 48px;
  }

  // @media {}
}
