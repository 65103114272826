.alert-icon {
  cursor: pointer;

  color: #eb5757;

  font-size: 36px;

  outline: none !important;
}

.borderCol {
  & + div.borderCol {
    padding-top: 8px;
    border-top: 1px solid #eff2f7;
  }
}
