@for $fs from 8 through 60 {
  .font-size-#{$fs} {
    font-size: #{$fs}px !important;
  }

  .font-size-lg-#{$fs} {
    @media (min-width: 1200px) {
      font-size: #{$fs}px !important;
    }
  }

  .fs-#{$fs} {
    font-size: #{$fs}px !important;
  }

  .fs-lg-#{$fs} {
    @media (min-width: 1200px) {
      font-size: #{$fs}px !important;
    }
  }
}

$fw: 300;
@while $fw <= 700 {
  .font-weight-#{$fw} {
    font-weight: #{$fw} !important;
  }
  .fw-#{$fw} {
    font-weight: #{$fw} !important;
  }
  $fw: $fw + 100;
}

@each $gray, $value in $grays {
  .text-#{$gray} {
    color: $value !important;
  }
}
@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: $value !important;
  }
}
@each $color, $value in $colors {
  .text-#{$color} {
    color: $value !important;
  }
}
