//
// Forms.scss
//

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: 1.25rem;
  .form-check-input {
    right: 0;
    margin-left: 0;
  }

  .form-check-label {
    display: block;
  }
}

.input-group-append {
  z-index: 0;
}

.input-disabled {
  background: #ececec !important;
  cursor: not-allowed !important;
}

.input-color {
  outline: none;
  color: $input-color !important;
  min-height: 38px;
  max-height: 46px;
  // min-height: 35.58px !important;
  // max-height: 35.58px !important;
  font-size: $input-font-size;

  &::placeholder {
    font-size: $input-font-size !important;
  }

  &:disabled {
    border-color: $input-disabled-border-color;
    cursor: not-allowed !important;
    &::placeholder {
      color: $input-disabled-placeholder-color;
      font-size: $input-font-size !important;
    }
  }
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.generate-new-password-btn {
  margin-top: 27px;
  @media (max-width: 991px) {
    width: 100%;
  }
}

.custom-switch .custom-control-label::after {
  background-color: #ffffff;
  height: 10px;
  width: 10px;
}

.custom-switch .custom-control-label::before {
  background-color: #e6eaf0;
  border: none;
  height: 15px;
  width: 30px;
  // margin-right: 12px;
}

@media (max-width: 768px) {
  .switch-cross .custom-switch .custom-control-label::after {
    background-color: #ffffff;
    height: 17px;
    width: 20px;
  }
  
  .switch-cross .custom-switch .custom-control-label::before {
    border: none;
    height: 22px;
    width: 40px;
    // margin-right: 12px;
  }

  .switch-cross label {
    font-size: 16px !important;
    font-weight: 350 !important;
  }
}

.form-control:disabled {
  background-color: rgba($gray-400, 0.5);
  cursor: not-allowed;
}

.btn:disabled {
  cursor: not-allowed;
}

.profile-picture {
  $diameter: 70px;
  width: $diameter;
  height: $diameter;
  border-radius: 50%;
  object-fit: cover;
  transition: all 300ms;

  &.edit-profile {
    cursor: pointer;
    &:hover {
      opacity: 75%;
      // transform: scale(1.1);

      // border: 5px solid blue;
    }
  }
}

.edit-profile-picture-btn {
  margin-left: 45px;
}

.react-datepicker-wrapper {
  .react-datepicker {
    &__input-container {
      input {
        background-color: $input-bg !important;
        &::placeholder {
          color: $black !important;
          font-weight: $font-weight-medium !important;
          font-size: $input-font-size !important;
        }
      }
    }
  }
}

.common-select {
  .react-select {
    &__single-value {
      font-weight: $input-font-weight;
      color: $input-color !important;
    }
  }
}

.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    margin-left: 12px;
  }
}

.select {
  &__control {
    border-color: $input-border-color !important;
    border-radius: $input-border-radius !important;
    background-color: $input-bg !important;
    // min-height: 46px !important;
    // max-height: 46px !important;
  }

  &__single-value {
    font-weight: $font-weight-medium;
    font-size: $input-font-size !important;
    color: $input-selected-value-color !important;
    opacity: 1 !important;
  }

  &__placeholder {
    color: $input-placeholder-color !important;
    font-weight: $input-font-weight !important;
    font-size: $input-font-size !important;
  }

  &__indicator {
    color: $input-color !important;
    font-weight: lighter !important;
  }

  &:focus {
    border-color: rgba($primary, 100%) !important;
    outline: none !important;
  }
}

.store-picker-old {
  // .app-search.form-control
  form {
    .css-2b097c-container {
      .react-select__control {
        border: none;
        height: 46px !important;
        box-shadow: none;
        border-radius: 30px !important;
        background-color: rgba($topbar-search-bg, 0.07) !important;
        width: 200px !important;

        .react-select__value-container {
          .react-select__single-value {
            color: rgba($white, 0.5) !important;
            font-weight: $font-weight-normal;
          }
        }
      }
    }
  }
  span,
  input.form-control::-webkit-input-placeholder {
    color: rgba($white, 0.5);
  }
}

.form-payment {
  .css-2b097c-container {
    .react-select__control {
      height: 38px !important;

      .react-select__value-container {
        .react-select__single-value {
          color: #495057 !important;
          font-weight: $font-weight-normal;
        }
      }
    }
  }
  span,
  input.form-control::-webkit-input-placeholder {
    color: #495057 !important;
  }
}

.react-select {
  &__control {
    border-color: $input-border-color !important;
    border-radius: $input-border-radius !important;
    background-color: $input-bg !important;

    height: 42px;
    // min-height: 46px !important;
    // max-height: 46px !important;
    width: 100% !important;
    // background-color: red !important;
    // min-height: 35.58px !important;
    // max-height: 35.58px !important;
  }

  &__single-value {
    font-weight: $font-weight-medium;
    color: $input-selected-value-color !important;
    font-size: $input-font-size !important;
    opacity: 1 !important;
  }

  &__placeholder {
    color: $input-placeholder-color !important;
    font-weight: $input-font-weight !important;
    font-size: $input-font-size !important;
  }

  &__indicator {
    color: $input-color !important;
    font-weight: lighter !important;
  }

  &:focus {
    border-color: rgba($primary, 100%) !important;
    outline: none !important;
  }

  -webkit-user-select: text !important; /* Chrome, Opera, Safari */
  -moz-user-select: text !important; /* Firefox 2+ */
  -ms-user-select: text !important; /* IE 10+ */
  user-select: text !important; /* Standard syntax */

  &.input-select-async {
    &__control {
      &:focus {
        border-color: rgba($primary, 100%) !important;
        outline: none !important;
      }
      border-color: $input-border-color !important;
      border-radius: $input-border-radius !important;
      background-color: $input-bg !important;
      // min-height: 46px;
      // max-height: 46px;
      // min-height: 35.58px;
    }

    // &__value-container {
    //   // max-height: 35.58px !important;
    //   // min-height: 46px;
    //   // max-height: 46px;
    // }

    &__single-value {
      font-weight: $font-weight-medium;
      color: $input-selected-value-color !important;
    }

    &__placeholder {
      color: $black;
      font-weight: 500;
      font-size: $input-font-size;
    }
  }
}

.custom-control-right {
  padding-left: 0;
  padding-right: 1.5rem;
  display: inline-block;
  .custom-control-label {
    display: inline-block;
    &:before,
    &:after {
      left: auto;
      right: -1.5rem;
    }
  }

  .custom-control-input {
    left: auto;
  }
}

// .custom-checkbox {
// input:checked {
//   color: red;
// }
// input:disabled {
// }
// .custom-control-label::before {
//   color: red;
//   background-color: red;
// }
// // .custom-control-input:checked,
// // .custom-control-label::before {
// //   color: $primary !important;
// //   background-color: $white !important;
// // }
// }

.react-datepicker-wrapper {
  height: 100%;
  max-height: 100%;
  .react-datepicker__input-container {
    height: 100%;
    max-height: 100%;
    .input-color {
      height: 100%;
      min-height: 100%;
      max-height: 100% !important;
    }
  }
}

.rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rating > input {
  display: none;
}

.rating > button {
  position: relative;
  width: 1em;
  font-size: 6vw !important;
  color: #ffd600 !important;
  cursor: pointer;
}
.rating > label::before {
  content: '\2605';
  position: absolute;
  opacity: 0;
}
.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}

.rating > input:checked ~ label:before {
  opacity: 1;
}

.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}

.card-body .switch-giftback {
  transform: scale(1.3);
  transform-origin: left center;
  margin-left: 10px;
}
