.background-order-details {
  background: #f8f8f8;
}

.btn-pix-details {
  color: white;
  background: #3abe5f;
  border-radius: 50px;

  &:hover {
    color: whitesmoke;
    background: #1e7436;
  }
}

.shadow-order-details {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.circle-step {
  display: flex;
  border-radius: 50%;
  max-width: 34px;
  min-width: 34px;
  max-height: 34px;
  min-height: 34px;
  padding: 10px;
  background: #fff;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
