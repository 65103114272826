.business-card {
    position: relative;
    padding: 100px 0 100px 0 !important;

    .seller-image {
        width: clamp(5rem, 50%, 10rem) !important;
    }

    @media (max-width: 400px) {
        .card {
            width: 90%;
        }
    }
}

.crm-new-customer-form {
    input,
    textarea {
        color: black !important;
        font-weight: 400;
    }

    .bg-secondary {
        background-color: #EEE !important;
    }

    .seller-card-header {
        flex-wrap: nowrap;
    }

    .seller-image {
        width: 3rem !important;
        height: 3rem !important;
        margin: 0 1rem;
    }

    .seller-title {
        text-align: start;
        margin: auto 0;
        font-size: 1.15rem;
    }

    @media (max-width: 250px) {
        .seller-card-header {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .seller-title {
            text-align: center;
        }
    }
}

.customer-card {
    height: calc(100% - 24px);
}

.reports-layout {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;

    * {
        margin: 0;
    }

    .reports-layout-summary {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .reports-layout-ranking {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;

        .ranking-first {
            background-color: var(--primary);
            color: white;
            border-radius: 8px;
        }

        button {
            margin-top: 0.5rem;
            font-size: 1rem;
            color: var(--primary);
            background: none;
            border: none;
        }
    }

    .reports-layout-total-customers {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;

        text-align: right;
    }

    .reports-layout-new-customers {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;

        text-align: right;
    }

    .reports-layout-graphic {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 4;
        grid-row-end: 5;
    }

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;

        .reports-layout-summary {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .reports-layout-ranking {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
        }

        .reports-layout-total-customers {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;

            text-align: left;

            h2 {
                font-size: 5rem;
            }
        }

        .reports-layout-new-customers {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;

            text-align: left;

            h2 {
                font-size: 5rem;
            }
        }

        .reports-layout-graphic {
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }

    .overflow-scroll {
        overflow-y: scroll;
    }
}

.crm-reports-modal .modal-content {
    margin-bottom: 10vh;
    max-height: 80vh;

    .overflow-scroll {
        overflow-y: scroll;
    }
}

.crm-control-input:checked~.crm-control-label::before {
    color: #fff;
    border-color: var(--primary);
    background-color: var(--primary);
}

.crm-edit-customer {
    textarea {
        height: 7rem !important;
        
        @media (max-width: 450px) {
            height: 8rem !important;
        }

        @media (max-width: 400px) {
            height: 9rem !important;
        }
    }
}