.float-left {
  float: left;
}

.float-right {
  float: right;
}

@media (max-width: 768px) {
  .break-text {
    // display: flex;
    // flex-wrap: wrap;
    word-break: break-all;
    font-size: 14px !important;
  }
}

.flip-h {
  transform: scaleX(-1);
}

.flip-v {
  transform: scaleY(-1);
}

.not-selectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.text-transparent {
  color: transparent !important;
}

.relative {
  position: relative;
  width: fit-content;
  i {
    position: absolute;
    top: 3px;
    right: 5px;
  }
}

.background-transparent {
  color: transparent;
}

.text-truncated {
  white-space: nowrap; /*keep text on one line */
  overflow: hidden; /*prevent text from being shown outside the border */
  text-overflow: ellipsis; /*cut off text with an ellipsis*/
}

.box-shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: none !important;
}

.outline-none {
  outline: none !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  pointer-events: none;
  cursor: not-allowed !important;
}

.pointer-events-none {
  pointer-events: none;
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none !important; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.text-decoration-line-through {
  text-decoration: line-through;
  label {
    text-decoration: line-through;
  }
}
.text-decoration-underline {
  text-decoration: underline !important;
  label {
    text-decoration: underline !important;
  }
}

.force-wrap {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  // white-space: -pre-wrap;                 /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.h-37px {
  height: 37px;
}

.grow-on-hover {
  @media (min-width: 1200px) {
    &:hover {
      transform: scale(1.02, 1.02);
    }
  }
}

.word-break-break-all {
  word-break: break-all !important;
}

.w-fit-content {
  width: fit-content !important;
}
.h-fit-content {
  height: fit-content !important;
}

.w-100-lg-fit-content {
  width: 100%;
  @media (min-width: 1200px) {
    width: fit-content;
  }
}

.w-lg-25 {
  @media (min-width: 1200px) {
    width: 25% !important;
  }
}
.w-lg-50 {
  @media (min-width: 1200px) {
    width: 50% !important;
  }
}

.w-lg-75 {
  @media (min-width: 1200px) {
    width: 75% !important;
  }
}

.w-lg-100 {
  @media (min-width: 1200px) {
    width: 100% !important;
  }
}

.w-65 {
  width: 65%;
}

.background-color-f2f2f2 {
  background-color: #f2f2f2;
}

.background-color-primary {
  background-color: $primary;
}
.background-color-white {
  background-color: $white;
}
.background-color-gray-100 {
  background-color: $gray-100;
}
.background-color-gray-200 {
  background-color: $gray-200;
}
.background-color-gray-300 {
  background-color: $gray-300;
}

.no-border-radius {
  border-radius: 0px !important;
}

.highlight-on-hover {
  :hover {
    background-color: #edf6ff;
  }
}
.highlight {
  background-color: #edf6ff;
}

.rounded-border-radius {
  &.right {
    border-radius: 24px 0px 24px 24px;
  }
  &.left {
    border-radius: 0px 24px 24px 24px;
  }
}

.mw-5 {
  max-width: 5%;
}
.mw-10 {
  max-width: 10%;
}
.mw-20 {
  max-width: 20%;
}
.mw-25 {
  max-width: 25%;
}
.mw-30 {
  max-width: 30%;
}
.mw-40 {
  max-width: 40%;
}
.mw-lg-40 {
  @media (min-width: 1200px) {
    max-width: 40% !important;
  }
}
.mw-50 {
  max-width: 50%;
}
.mw-60 {
  max-width: 60%;
}
.mw-70 {
  max-width: 70%;
}
.mw-75 {
  max-width: 75%;
}
.mw-80 {
  max-width: 80%;
}
.mw-90 {
  max-width: 90%;
}
.mw-100 {
  max-width: 100%;
}

.float-lg-right {
  @media (min-width: 1200px) {
    float: right !important;
  }
}
.float-lg-left {
  @media (min-width: 1200px) {
    float: left !important;
  }
}
