.dropzone {
  height: 300px;
  background: #e1faec;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
}

.dropzone img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dropzone p {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
}

.dropzone p svg {
  color: #4ecb79;
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}
