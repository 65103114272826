.container-float {
  position: fixed;
  width: 210px;
  height: 60px;
  bottom: 70px;
  right: 40px;
  background-color: white;
  border: 1px solid #34c38f;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  opacity: 0.4 !important;
  transition: all 0.5s ease;

  &:hover {
    opacity: 1 !important;
  }
}

@media (max-width: 768px) {
  .container-float {
    display: none;
  }
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 70px;
  right: 40px;
  background-color: #34c38f;
  color: #fff;
  border-radius: 50px;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.float:hover {
  background-color: #2ea378;
  color: #fff;
}

.my-float {
  margin-top: 14px;
}

.space-whatsapp-button {
  margin-bottom: 6vh;
}
