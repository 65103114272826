.barcode-scanner-btn {
  width: 100%;
}

.barcode-scanner {
  $barcode-target-width: 90vw;
  $barcode-target-height: 25vh;

  $barcode-horizontal-center: 40vh;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: 1002;

  i {
    position: fixed;
    top: 30px;
    right: 30px;

    z-index: 1006;

    font-size: 3rem;
    width: 3rem;
    height: 3rem;
    color: $white;
  }

  video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    object-fit: cover;
    z-index: 1003;
  }

  .bs-msg {
    position: fixed;
    top: 15vh;
    left: calc(50vw - #{$barcode-target-width/2});
    z-index: 1006;

    width: $barcode-target-width;

    font-size: 18px;
    font-weight: 400;
    color: $white;
    text-align: center;
  }

  .bs-target-area {
    position: fixed;
    top: calc(#{$barcode-horizontal-center} - #{$barcode-target-height/2});
    left: calc(50vw - #{$barcode-target-width/2});

    width: $barcode-target-width;
    height: $barcode-target-height;

    box-shadow: 0 0 0 100vh rgba($black, 0.5);

    z-index: 1005;

    .bs-target-line {
      $line-height: 2px;

      width: 100%;
      height: $line-height;
      position: absolute;
      top: calc(50% - #{$line-height});
      background-color: $danger;
    }
  }

  .type-barcode-btn {
    position: fixed;
    bottom: 25vh;
    left: calc(50vw - #{$barcode-target-width/2});
    z-index: 1006;

    width: $barcode-target-width;

    font-size: 18px;
    font-weight: 400;
    color: $white;

    background-color: transparent;
    border: 1px solid $white;
    border-radius: 4px;
  }
}
