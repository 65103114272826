//
// _helper.scss
//

// Cursor
.cursor-pointer {
  cursor: pointer;
}

// Font weight help class

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 1px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
}

.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

// Images

.w-gateway {
  max-width: 100px;
}

.h-payment100 {
  max-height: 60px !important;
}

@media (max-width: 768px) {
  .h-payment100 {
    max-height: 60px !important;
  }
}
