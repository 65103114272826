.omnichat-badge {
  height: 20px;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;

  background-color: rgba($warning, 0.15);
  color: $warning;

  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;
}
.shareable-cart-badge {
  height: 20px;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;

  background-color: rgba($info, 0.15);
  color: $info;

  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;
}

.scheduled-delivery-badge {
  height: 20px;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;

  background-color: rgba($purple, 0.15);
  color: $purple;

  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;
}

.store-justa-status {
  font-size: 12px !important;
  font-weight: 400 !important;

  &.inactive {
    background-color: transparent;
    color: $danger;
  }
  &.pending {
    background-color: transparent;
    color: $warning;
  }
  &.active {
    background-color: transparent;
    color: $success;
  }
}

.store-delivery-status {
  height: 20px;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;

  &.not_registered {
    background-color: rgba($danger, 0.15);
    color: $danger;
  }
  &.not_ready {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }
  &.ready {
    background-color: rgba($success, 0.15);
    color: $success;
  }

  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;
}

.sale-status-badge {
  // height: 20px;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;

  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;

  white-space: pre-wrap;

  &.cart-details {
    height: 48px;
    width: 100%;
    padding: 16px !important;
    border-radius: 2px !important;

    font-size: 14px !important;
    font-weight: 600 !important;
    text-align: start;
    margin-bottom: 18px;
  }

  // $success: #60b05d;
  // $primary: #2544b1;
  // $warning: #eca120;
  // $danger: #eb5757;

  &.badge-gray {
    background-color: #EAEAEA;
    color: #575857;
  }

  &.badge-green {
    background-color: rgba($success, 0.15);
    color: $success;
  }

  &.badge-blue {
    background-color: rgba($primary, 0.15);
    color: $primary;
  }

  &.badge-info {
    background-color: rgba($info, 0.15);
    color: $info;
  }

  &.badge-yellow {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }

  &.badge-red {
    background-color: rgba($danger, 0.15);
    color: $danger;
  }

  &.badge-authorized {
    background-color: rgba(#b3a5ef, 0.15);
    color: #967ada;
  }

  &.badge-cyan {
    background-color: rgba(26, 161, 232, 0.15);
    color: #06847a;
  }

  &.badge-pink {
    background-color: rgba(232, 48, 138, 0.1);
    color: #E8308A;
  }

  &.badge-pink-low {
    background-color: rgba(252, 101, 174, 0.1);
    color: #EB87BF;
  }

  &.badge-blue-low {
    background-color: rgba(95, 163, 247, 0.1);
    color: #4B89DA;
  }

  &.badge-skin {
    background-color: rgba(253, 220, 149, 0.1);
    color: #E4B693;
  }

  &.badge-orange {
    background-color: rgba(252, 154, 88, 0.1);
    color: #F86D51;
  }
}

.user-sellers-badge {
  white-space: normal !important;
  border-radius: 8px !important;
  font-size: 10px !important;
  font-weight: 400 !important;

  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;
  background-color: rgba($primary, 0.15);
  color: $primary;

  &.mobile {
    font-size: 12px !important;
  }
}

.delivery-method-badge {
  // height: 20px;
  white-space: normal !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;

  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;

  &.BEE {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }
  &.MOTTU {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }
  &.UBER {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }
  &.CORREIOS {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }
  &.LALAMOVE {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }
  &.IBOLT {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }
  &.PROPRIA {
    background-color: rgba($primary, 0.15);
    color: $primary;
  }
}

.role-badge {
  &.salesman {
    background-color: rgba($success, 0.15);
    color: $success;
  }

  &.stockist {
    background-color: rgba($purple, 0.15);
    color: $purple;
  }

  &.digital-consultant {
    background-color: rgba($primary, 0.15);
    color: $primary;
  }

  &.cross-manager {
    background-color: rgba($pink, 0.15);
    color: $pink;
  }

  &.store-manager {
    background-color: rgba($info, 0.15);
    color: $info;
  }

  &.administrator {
    background-color: rgba($warning, 0.15);
    color: $warning;
  }

  &.financier {
    background-color: rgba($success, 0.15);
    color: $success;
  }
}

.gray-badge {
  background-color: rgba(grey, 0.15) !important;
  color: grey !important;
}

.group-chat-badge {
  background-color: rgba($danger, 0.15);
  color: $danger;
}
