.filter-text-desktop {
  display: block;
}

.filter-text-mobile {
  display: none;
}

@media (max-width: 768px) {
  .filter-text-desktop {
    display: none;
  }

  .filters {
    display: none !important;
  }

  .filter-text-mobile {
    display: block;
  }
}

.stocks {
  &__item {
    transition: 450ms;

    &.dc {
      box-shadow: none;
    }

    &:hover {
      transform: scale(1.05, 1.05);
      box-shadow: 0px 3px 8px 0px rgba(15, 76, 129, 0.1);
      cursor: pointer;
    }

    &--delete {
      opacity: 0;
      transform: translate(140px, 0px) scale(1, 0.6);
      transition: 300ms linear;
    }
  }

  &:hover &__item:not(:hover) {
    opacity: 0.5;
  }
}

.stock-item {
  opacity: 1;
  &--open {
    opacity: 0;
  }
}

.stock-transition {
  &__container {
    display: block;
    height: 0px;
    transition: 450ms;
    opacity: 0;
    pointer-events: none;
    &--open {
      opacity: 1;
      height: 100%;
    }
  }
  &__button {
    transition: 450ms;
  }
}

.mark-as-separated-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
