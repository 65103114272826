.link-pix-input {
  border: 1px solid #cccccc;
  border-radius: 8px;
  height: 44px;
  width: 100%;

  &::placeholder {
    margin-left: 20px;
  }

  .react-select__control {
    border: none;
    border-radius: 0 !important;
    background-color: transparent !important;
  }
}

@media (max-width: 768px) {
  .padding-adjusts-label {
    padding: 0px 12px !important;
  }
}
