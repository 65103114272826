.loading {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  i {
    font-size: 3rem;
    transition: 450ms;
    animation: spinner 600ms linear infinite;
  }
}

.loadingpix {
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  i {
    font-size: 3rem;
    transition: 450ms;
    animation: spinner 600ms linear infinite;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
