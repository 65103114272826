//
// ecommerce.scss
//

// product

.search-box {
  .form-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;

    box-shadow: 2px 4px 4px rgba($black, 0.08) !important;
    
    border-radius: 4px;
    
    padding-left: 35px;
    height: 37px;

    color: $search-box-placeholder-color !important;
    font-weight: $font-weight-normal !important;
    font-size: $input-font-size !important;

    &::placeholder {
      color: $search-box-placeholder-color !important;
      font-weight: $font-weight-normal !important;
      font-size: $input-font-size !important;
    }
  }

  .search-icon {
    color: $search-box-placeholder-color;
    font-size: 14.5px;
    position: absolute;
    left: 12px;
    top: 0;
    line-height: 37px;
  }
}

.product-list {
  li {
    a {
      display: block;
      padding: 4px 0px;
      color: $body-color;
    }
  }
}

.product-view-nav {
  &.nav-pills {
    .nav-item {
      margin-left: 4px;
    }
    .nav-link {
      width: 36px;
      height: 36px;
      font-size: 16px;
      padding: 0;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.product-ribbon {
  position: absolute;
  right: 0px;
  top: 0px;
}

// Product Details

.product-detai-imgs {
  .nav {
    .nav-link {
      margin: 7px 0px;

      &.active {
        background-color: $gray-300;
      }
    }
  }
}

.product-color {
  a {
    display: inline-block;
    text-align: center;
    color: $body-color;
    .product-color-item {
      margin: 7px;
    }
    &.active,
    &:hover {
      color: $primary;
      .product-color-item {
        border-color: $primary !important;
      }
    }
  }
}

// ecommerce cart

.visa-card {
  .visa-logo {
    line-height: 0.5;
  }

  .visa-pattern {
    position: absolute;
    font-size: 385px;
    color: rgba($white, 0.05);
    line-height: 0.4;
    right: 0px;
    bottom: 0px;
  }
}

// checkout

.checkout-tabs {
  .nav-pills {
    .nav-link {
      margin-bottom: 24px;
      text-align: center;
      background-color: $card-bg;
      box-shadow: $box-shadow;

      &.active {
        background-color: $primary;
      }
      .check-nav-icon {
        font-size: 36px;
      }
    }
  }
}
