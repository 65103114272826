//
// modal.scss
//

.modal-header {
  // height: 8vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
}

.modal-title {
  font-weight: $font-weight-bold;
  color: $gray-700;
}

.modal-body {
  color: $gray-600;
  font-weight: $font-weight-medium;
}

.modal-body-text {
  color: $gray-600;
  font-weight: $font-weight-medium;
}

.regions-modal {
  // height: px !important;
  &.loading {
    padding: 0px 0px 0px 0px !important;
  }
  .loading {
    height: 350px !important;
  }
  .scrollbar-container {
    height: 200px;
    // width: 80%;
  }
}

.region {
  height: 40px;
  padding: 12px;
  border-radius: 10px;
  outline: none;
  &:hover {
    background-color: #eff2f7;
  }
  &.selected {
    background-color: #eff2f7;
  }
}

.modal-dialog {
  top: 10vh;
}

.rating-modal {
  &.modal-dialog {
    top: 30%;
  }

  .modal-content {
    border-radius: 10px;
  }
  .modal-footer {
    border-top: 0px;
  }
  .modal-header {
    border-bottom: 0px;
  }
}
