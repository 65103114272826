@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

// body {
//   // &.modal-open {
//   // padding-right: 0px !important;
//   // }
//   &.modal-open {
//     // overflow: scroll;
//     padding-right: 30px !important;
//   }
// }

.gift-switch {
  z-index: 0 !important;
}

header {
  height: $header-height !important;
}

.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.4;
}

// .choose-regions-modal {
//   .modal-content {
//     // height: 415px ;
//   }
// }

// .confirm-region-modal {
//   .modal-content {
//     // height: 200px;
//   }
// }

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}

.warning {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.page-link {
  z-index: 0 !important;
}

blockquote {
  background-color: #f2f2f5;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  font-style: italic;
  border-left: 5px solid #ccc;
  quotes: '\201C''\201D''\2018''\2019';
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.custom-control {
  z-index: 0 !important;
}

#pix-link:disabled {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#pix-link:disabled::selection {
  background: none;
}

#pix-link:disabled::-moz-selection {
  background: none;
}

body {
  background-color: #f3f3f3;
}