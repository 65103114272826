.page-content {
  &.dc-layout {
    padding-top: 12px !important;
    @media (min-width: 1200px) {
      padding-top: 24px;
      height: 100%;
      margin: auto;
      max-width: 576px;
      min-height: 80vh;
    }
  }
}

body {
  &.dc-layout,
  &.shared-cart-layout {
    @media (min-width: 1200px) {
      background-color: $gray-400;
      height: 100%;
    }
  }
}

#root {
  &.dc-layout,
  &.shared-cart-layout {
    @media (min-width: 1200px) {
      background-color: #f8f8fb;
      margin: auto;
      // max-width: 576px;
      height: 100%;
      // background-color: red !important;
    }
  }
  &.dc-layout {
    min-height: 100vh;
  }
}

footer {
  &.dc-layout {
    @media (min-width: 1200px) {
      // background-color: red;
      margin: auto !important;
      max-width: 576px;
      margin: auto;
      left: 0;
    }
  }
}

// &.dc-cart-details {
//   // padding-top: 75px !important;
// }

.main-content {
  // margin-top: 70px;
  transition: all ease 0.5s;
  &.dc-layout {
    margin-left: 0px !important;
    width: 100%;

    &.sidebar-open {
      max-width: calc(100% - #{$sidebar-width}) !important;
      margin-left: $sidebar-width;
    }
    // padding-top: 70px !important;
    @media (min-width: 1200px) {
      padding-top: 12px;
    }
  }
}

.dc-auth {
  background-color: white;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;

  .dc-container {
    width: 100%;
    // height: 100vh;
    position: absolute;
    top: 35vh;
    // right: 25%;
  }

  &.register {
    height: 125vh;
    .dc-container {
      top: 37.5vh;
    }
  }
}

.dc-auth-form-group {
  .form-group {
    label {
      float: left;

      font-size: 14px;
      font-weight: 500;

      color: #6a7187;
    }
  }
}

.dc-forgot-password {
  float: right;
  margin-bottom: 24px;

  font-size: 12px;
  font-weight: 400;
  color: #2544b1;
}

.dc-auth-form {
  width: 90%;
}

.dc-auth-welcome-msg {
  font-size: 20px;

  width: 208px;
  height: 60px;

  color: $gray-700;

  &.register {
    margin-bottom: 36px;
  }
}

.dc-register-btn-div {
  margin-top: 40px;
  margin-bottom: 40px;
}

.dc-logo-col {
  width: 100vw;
  height: 30vh;
  position: absolute;
  top: 8vh;

  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  img {
    margin-bottom: 48px;
  }

  // @media {}
}

.dc-header-btn-container {
  margin: 0px;
}

.dc-header-logo {
  margin-top: 12px;
  margin-left: 12px;
  z-index: 3000;
}

.dc-header-toggle {
  padding: 0px;
}

.dc-header-search {
  padding: 0px;
}

.dc-sidebar-user-role-badge {
  margin-right: 36px;
  margin-top: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  border-radius: 8px;
}

.dc-sidebar-user {
  padding-left: 24px;
  padding-top: 12px;
  margin-bottom: 48px;

  .dc-sidebar-profile-pic {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
  }

  .dc-sidebar-user-name {
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
  }
}

.dc-auth-logo {
  width: 70px;
  height: 45.55px;

  // margin-left: 24px;
}

.dc-auth-register-msg {
  font-size: 14px;
  font-weight: 400px;
  color: #333333;
  a {
    font-weight: 700;
  }
}

.dc-register-login-msg {
  margin-top: 42px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    font-weight: 700;
  }
}

.dc-auth-btn {
  font-size: 16px;
  font-weight: 400;
}

.dc-sidebar-logout-btn-text {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.dc-register-sale-nav-tab {
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  border: none !important;
  background-color: #7a8195 !important;
  padding: 0px 0px 0px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 14px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  text-align: center !important;
  vertical-align: middle;

  &.active {
    background-color: #2544b1 !important;
  }
}

.dc-register-sale-nav-tab-group {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 16px;
  border: none;
}

.page-content {
  &.dc-register-sale {
    background-color: #f8f8fb;
    // padding-top: 65px !important;
    .container-md {
      background-color: #f8f8fb;
    }
  }
  // &.dc-edit-data {
  //   // padding-top: 65px !important;
  // }
}

.dc-register-sale {
  &.section-label {
    font-size: 18px;
    font-weight: 700;
    color: $gray-700;
    margin-bottom: 14px;
  }

  &.input-label {
    font-size: 14px;
    font-weight: 500;
    color: #6a7187;
    margin-bottom: 8px;
  }

  &.order-number {
    font-size: 14px;
    font-weight: 300;
    color: #7a8195;
  }

  &.card-body {
    padding: 24px !important;
  }

  &.product-name-and-value {
    font-size: 14px;
    font-weight: 300;
    color: #2544b1;
  }

  &.product-list-card {
    border: 1px solid rgba(#74788d, 0.5);
    background-color: rgba(243, 243, 243, 0.5);
    padding: 12px !important;
  }
}

.product-list-entry {
  color: $gray-700;
  &.code-label {
    font-size: 12px;
    font-weight: 600;

    margin-right: 4px;
  }
  &.code-value {
    font-size: 12px;
    font-weight: 400;
  }
  &.amount {
    font-size: 14px;
    font-weight: 400;
  }
  &.name {
    font-size: 14px;
    font-weight: 700;
  }
  &.price {
    font-size: 16px;
    font-weight: 400;
  }
}

.dc-payment-way-row {
  margin-top: 24px;
}

.dc-register-sale-form-group {
  .input-color {
    height: 40px !important;
    min-height: 40px !important;
    max-height: 40px !important;
  }

  .react-select__control {
    width: 100% !important;
    height: 40px;
  }

  .dc-discount-input {
    width: 100% !important;
    height: 40px;
    outline: none;
    border: 1px solid rgba(#74788d, 0.5);
    box-sizing: border-box;
    border-radius: 2px;
    padding-left: 12px;

    color: $input-color !important;
    font-size: $input-font-size;

    &:focus {
      border: 1px solid #2544b1;
    }

    &::placeholder {
      font-size: $input-font-size !important;
    }

    &:disabled {
      border-color: $input-disabled-border-color;
      cursor: not-allowed !important;
      &::placeholder {
        color: $input-disabled-placeholder-color;
        font-size: $input-font-size !important;
      }
    }
  }
}

.dc-order-resume-card {
  background-color: #f8f9fa;
}

.dc-register-sale-order-resume {
  &.title {
    font-size: 18px;
    font-weight: 700;
    color: $gray-700;
    margin-bottom: 12px;
  }
  &.label {
    font-size: 14px;
    font-weight: 500;
    color: #6a7187;
    margin-bottom: 4px;
  }
  &.value {
    font-size: 14px;
    font-weight: 300;
    color: $gray-700;
    margin-bottom: 8px;
    &.payment {
      font-weight: 500;
      color: #2544b1;
    }
  }

  &.final-price {
    font-size: 18px;
    font-weight: 700;
    color: #333333;
    margin-top: 12px;
  }
}

.dc-register-sales-btn {
  min-height: 40px;
  border-radius: 4px !important;
  font-size: 16px !important;
  font-weight: 400 !important;

  i {
    font-size: 14px;
    margin-right: 8px;
  }
}

.generate-cielo-link-btn {
  background-color: transparent;
  border: none;

  color: #2544b1;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}

.dc-use-percentage-label {
  font-size: 14px;
  font-weight: 400;
  color: $gray-700;
  margin-bottom: 14px;
}

.dc-custom-input {
  font-size: 15px;
  font-weight: 400;
  color: #646d82;
  margin-bottom: 14px;
}

.dc-register-sales-amounts {
  font-size: 14px;
  font-weight: 400;
  color: $gray-700;
  margin-bottom: 14px;
  // &.subtotal {
  // }
  &.discount {
    color: #60b05d;
  }
  &.final-price {
    font-size: 18px;
    font-weight: 700;
  }
}

.dc-register-sale-bank-data {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  &.main-label {
    font-weight: 700;
  }
}

.dc-sidebar-toggle {
  position: fixed;
  bottom: 50vh;
  margin-right: 12px;
  @media screen and (orientation: landscape) {
    bottom: 25vh;
  }
}

.dc-header-notifications-btn {
  width: fit-content;
  height: 100%;
  padding: 0px;

  i {
    color: $white !important;
  }
}

.dc-register-sale-col {
  padding-left: 24px;
}

.dc-register-sale-back-btn {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  i {
    margin-right: 14px;
    margin-bottom: 2px;
  }
}

// .mobile-to-register-sale-btn {
//   position: fixed !important;
//   bottom: 1.5%;
//   max-width: calc(100% - 24px);
//   height: 50px;
//   z-index: 1001;

//   @media (min-width: 1200px) {
//     $btn-width: 552px;
//     bottom: 48px;
//     max-width: $btn-width;
//     left: calc(50% - (#{$btn-width} / 2));
//   }

//   font-size: 16px !important;

//   i {
//     font-size: 14px;
//     margin-right: 4px;
//   }
// }

.dc-sale-number-col {
  font-size: 14px;
  font-weight: 400;
  color: $gray-700;

  i {
    font-size: 18px;
    margin-left: 20px;
  }
}

.dc-sale-card-info-col {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.dc-sale-card-label {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  margin-right: 6px;
  margin-right: 6px;
}

.dc-sale-card-value {
  color: $gray-700;

  &.amount {
    font-size: 14px;
    font-weight: 400;
  }

  &.client-name {
    font-size: 12px;
    font-weight: 600;
  }
}

.dc-cart-details {
  &.card {
    box-shadow: 0px 10px 20px 0px rgba(#12263f, 0.0314) !important;
    cursor: pointer; // padding: 16px 30px 16px 16px;
  }

  &.order-details-main {
    font-size: 16px;
    color: $gray-700;

    &.label {
      font-weight: 600;
    }
    &.order-number {
      font-weight: 400;
    }
  }

  &.card-info {
    font-size: 14px;

    &.label {
      font-weight: 600;
      color: #74788d;
    }

    &.value {
      font-weight: 700;
      color: #000000;
    }
  }

  &.show-more {
    color: $gray-700;
    position: relative;

    i {
      position: absolute;
      top: 50%;
      right: 10%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.dc-customer-card {
  &.label {
    font-size: 13px;
    font-weight: 600;
    color: #74788d;
  }

  &.value {
    font-size: 14px;
    font-weight: 400;
    color: #2c2c2c;
  }
}

.dc-payment-card {
  &.payment-detail {
    font-size: 18px;
    font-weight: 600;
  }
  &.label {
    font-size: 13px;
    font-weight: 600;
    color: #74788d;
  }

  &.value {
    font-size: 13px;
    font-weight: 400;
    color: #2c2c2c;
  }
}

.dc-delivery-card {
  &.label {
    font-size: 13px;
    font-weight: 600;
    color: #74788d;
  }

  &.value {
    font-size: 13px;
    font-weight: 400;
    color: #2c2c2c;
  }
}

.dc-cart-details-product-card {
  padding: 12px;
  box-shadow: none !important;
  border: 1px solid rgba($gray-700, 0.25) !important;
}

.dc-cart-details-product-card-section {
  color: $gray-700;

  &.row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  &.label {
    font-size: 12px;
    font-weight: 600;
    margin-right: 4px;
  }
  &.value {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
  }
}

.dc-cart-details-btn {
  min-height: 40px;
  border-radius: 4px !important;
  font-size: 16px !important;
  font-weight: 400 !important;

  i {
    font-size: 14px;
    margin-right: 8px;
  }
}
