.new-layout-register-page {
  background-color: rgba(#e5e5e5, 0.15);
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;

  @media (max-width: 1199px) {
    background-color: $white;
  }
}
.new-layout-register-card {
  max-width: 550px;
  // max-height: 530px;
  margin: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 1199px) {
    box-shadow: none;
  }
}

.new-layout-register-btn-div {
  margin-top: 40px;
  margin-bottom: 40px;
}
