// body,
// footer,
// #root,
// .page-topbar,
// .main-content,
// .page-content,
// .pro-sidebar-header {
//   @extend .new-layout;
// }

.page-header {
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 1199px) {
    $mt: 24px;
    margin-top: $mt;
    margin-bottom: $mt;
  }
}

.store-in-page-filter {
  height: 37px;
  width: 125px;
  &::placeholder {
    font-weight: 500;
    color: black;
  }
}

// .MuiInputBase-input {
//   @extend .cursor-pointer;
// }

// .MuiFormControl-root {
//   @extend .w-100;
//   @extend .w-lg-fit-content;
// }

.appearence-none {
  -webkit-appearance: none !important;
}

// .sales-filters-row {
//   :not(:first-child) {
//     // @include ml-2;
//   }
// }

.multiline-input::placeholder {
  padding-top: 4px;
}

.mobile-to-register-sale-btn {
  position: fixed !important;
  bottom: 1.5%;
  max-width: calc(100% - 24px);
  height: 50px;
  z-index: 1001;

  @media (min-width: 1200px) {
    $btn-width: 552px;
    bottom: 48px;
    max-width: $btn-width;
    left: calc(50% - (#{$btn-width} / 2));
  }

  font-size: 16px !important;

  i {
    font-size: 14px;
    margin-right: 4px;
  }
}

.search-bar-wrapper {
  transition: all 300ms ease 0s;
  padding-left: calc(#{$sidebar-collapsed-width} + 35px);
  &.sidebar-open {
    padding-left: calc(#{$sidebar-width} + 35px);
  }
}

.new-layout-text-filter {
  width: 180px;
  height: 37px;

  &::placeholder {
    font-weight: 500;
    color: $black;
  }
  @media (max-width: 399px) {
    width: 340px;
  }
}

.mask-filter {
  min-height: 0 !important;
  height: 37px !important;

  &::placeholder {
    font-weight: 500;
    color: $black;
  }
}

.return-button {
  background-color: transparent;
  border: none;
  color: $gray-700 !important;

  font-size: 14px;
  font-weight: 500;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.new-layout-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;

  height: 38px;

  border-radius: 4px;

  &.review-btn {
    height: inherit;
  }

  &.main {
    @media (max-width: 1199px) {
      width: 100%;
    }

    &.long-label {
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
  }
}

.new-layout-btn-copy {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  height: 30px;

  border-radius: 4px;
  .las {
    font-size: 20px;
  }

  &.main {
    @media (max-width: 1199px) {
      width: 100%;
    }

    &.long-label {
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
  }
}

.new-layout-btn-transfer {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;

  border-radius: 4px;

  &.main {
    @media (max-width: 1199px) {
      width: 100%;
    }

    &.long-label {
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
  }
}

.new-layout-btn-transparent {
  background-color: transparent;
  color: #2544b1;

  &:hover {
    background-color: #2544b1;
    color: white;

    a {
      color: white;

      span {
        color: white;
      }
    }
  }
}

.bg-layout-lite {
  background-color: #f8f8f8;

  i {
    color: #0f80ee;
  }
  b {
    color: #0f80ee;
  }
  a {
    color: #0f80ee;
  }
}

.color-blue-lite {
  color: #0f80ee !important;
  outline: none !important;
}

.container {
  padding: 0px;
  &.new-layout {
    width: 75%;
    // margin-left: 50px;
    // margin-right: 50px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1199px) {
      width: 100%;
    }

    &.wide {
      $margin: 24px;
      margin-left: $margin;
      margin-right: $margin;
      width: calc(100% - 2 *#{$margin});
      max-width: 100%;

      @media (max-width: 1199px) {
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
      }
    }
  }
}

.card {
  &.new-layout {
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.05);
    // box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);

    &.table-card {
      margin-top: 0px;
      margin-bottom: 0px;

      border-radius: 0px;

      &.single-row {
        border: 0.5px solid $gray-400;
      }

      &.first-row {
        border: 0.5px solid $gray-400;
        border-bottom: 0.5px solid $gray-300;
      }

      &.middle-row {
        border: 0.5px solid $gray-400;
        border-top: 0.5px solid $gray-300;
        border-bottom: 0.5px solid $gray-300;
      }

      &.last-row {
        border: 0.5px solid $gray-400;
        border-top: 0.5px solid $gray-300;
      }
    }
    &.list-card {
      transition: 0.5s ease;

      height: 80px;
      @media (max-width: 1199px) {
        height: fit-content;
      }

      &.alt {
        height: fit-content;
        .card-title {
          font-size: 18px;
          font-weight: 700;
          color: $gray-700;
        }
        .card-value {
          font-size: 16px;
          font-weight: 500;
          color: $gray-600;
        }
      }

      &.sale {
        cursor: pointer;
      }

      &.store {
        height: fit-content;
      }
    }

    @media (max-width: 1199px) {
      // &.new-layout {
      // background-color: transparent;
      border: none;
      box-shadow: none;
      // }
    }
  }
}

.card-body {
  &.new-layout {
    padding: 3rem 4rem 3rem 4rem;
    @media (max-width: 1199px) {
      padding: 1.25rem;
    }
  }
}

label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #6a7187 !important;
}

.store-ticket {
  background-color: rgba($info, 0.2);
  height: 30px;
  width: 100%;
  border-radius: 4px;

  padding: 5px 6px 3px 12px;

  font-size: 14px;
  font-weight: 300;
  color: $gray-700;
}

.new-layout-table-column-label {
  font-size: 14px;
  font-weight: 600;
  color: $black;

  @include text-truncate;
}
.new-layout-table-column-value {
  // height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: $gray-700;
}

.new-layout-card-list-strong-value {
  font-size: 13px;
  font-weight: 700;
  color: $gray-700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.new-layout-card-list-half-strong-value {
  font-size: 13px;
  font-weight: 600;
  color: $gray-700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.new-layout-card-mobile {
  &.label {
    font-size: 12px;
    font-weight: 600;
    color: $black;
    margin-right: 0.5rem;
  }
  &.value {
    font-size: 14px;
    font-weight: 400;
    color: $gray-700;
    max-width: 75%;
  }
}

.new-layout-user-card-label-col-width {
  // min-width: 17%;
  min-width: 18%;
  @media (min-width: 768px) {
    min-width: 10%;
  }
}

.new-layout-dropdown-filter,
.new-layout-batch-actions {
  // display: inline;

  // height: 37px;
  // min-width: 25%;
  // max-width: 75%;

  .select__control {
    // max-width: 100%;
    // max-height: 37px !important;

    // padding: 8px;

    border: 1px solid #ced4da;
    border-radius: 4px !important;
  }
}

.new-layout-page-value {
  // height: 100%;

  // display: flex;
  // align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: $gray-700;
}

.new-layout-managed-stores-scrollbar {
  &.edit {
    max-height: 165px;
  }
  &.details {
    max-height: 200px;
  }
}

.block-card {
  &.card-title {
    font-size: 13px;
    font-weight: 600;
    color: $gray-700;
  }

  &.value {
    font-size: 14px;
    font-weight: 400;
    color: $gray-700;
  }

  &.label {
    font-size: 10px;
    font-weight: 600;
    color: $gray-500;
  }

  &.details-link {
    font-size: 10px;
    font-weight: 500;
    color: $info;
    text-decoration: underline !important;
  }
}

.code-mobile-card-title {
  font-size: 14px;
  font-weight: 700;
  color: $black;
}

.new-layout-nav-pill {
  &.card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.tab-active {
  border-bottom: 2px solid $primary !important;
}

@each $color, $value in $theme-colors {
  .border-bottom-#{$color} {
    border-bottom: 2px solid $value !important;
  }
}
@each $gray, $value in $grays {
  .border-bottom-#{$gray} {
    border-bottom: 1px solid $value !important;
  }
}

.products-table-row {
  border-bottom: 1px solid $gray-200;
  &.header {
    border-top: 1px solid $gray-200;
  }
}

.cart-details-product-mobile-card {
  padding: 12px;
  box-shadow: none !important;
  border: 1px solid rgba($gray-700, 0.25) !important;
}

.circle-nav-tab {
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  border: none !important;
  background-color: #7a8195 !important;
  padding: 0px 0px 0px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 14px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  text-align: center !important;
  vertical-align: middle;

  &.active {
    background-color: #2544b1 !important;
  }
}

@for $hs from 8 through 60 {
  .h-#{$hs}px {
    height: #{$hs}px !important;
  }

  .h-lg-#{$hs}px {
    @media (min-width: 1200px) {
      height: #{$hs}px !important;
    }
  }
}

.new-layout-nav-pill {
  &.checked {
    background-color: $primary;
    color: #ffffff !important;
    pointer-events: none;
    cursor: default;
  }

  &.unchecked {
    background-color: transparent;
    color: $primary;
    border: 1px solid #bdbdbd;
  }

  &.center {
    justify-content: center;
  }
  text-align: center;

  height: 52px;

  font-size: 16px;
  font-weight: 700;

  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;

  margin-bottom: 12px !important;

  box-shadow: none;
  border-radius: 2px;
  padding: 18px;

  text {
    align-self: center;
  }

  &.rounded {
    border-radius: 65px !important;
  }

  // i {
  //   // font-size: 14px;
  // }
}

.dashboard-custom-icon {
  $diameter: 48px;
  width: $diameter;
  height: $diameter;

  border-radius: 50%;

  background-color: $primary;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .shine-diagonal-beam {
    z-index: 10;
    width: 12px;
    height: 54px;
    position: absolute;

    background-color: rgba(255, 255, 255, 0.101961);
    transform: rotate(30deg);
  }
}

.dashboard-card-border {
  border: 1px solid rgba(116, 120, 141, 0.5) !important;
  border-radius: 3.25px;
}

.donut-card-item-pointer {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 7px;
  margin-right: 10px;

  &.primary {
    background-color: $primary;
  }
  &.danger {
    background-color: $danger;
  }
  &.warning {
    background-color: $warning;
  }
  &.success {
    background-color: $success;
  }
}

.new-layout-table-row {
  margin-top: 0px;
  margin-bottom: 0px;

  border-radius: 0px;

  &.single-row {
    border: 0.5px solid $gray-400;
  }

  &.first-row {
    border: 0.5px solid $gray-400;
    border-bottom: 0.5px solid $gray-300;
  }

  &.middle-row {
    border: 0.5px solid $gray-400;
    border-top: 0.5px solid $gray-300;
    border-bottom: 0.5px solid $gray-300;
  }

  &.last-row {
    border: 0.5px solid $gray-400;
    border-top: 0.5px solid $gray-300;
  }
}

.new-layout-table-border-bottom {
  border-bottom: 0.5px solid $gray-200;
}

.new-layout-table-border-top {
  border-top: 0.5px solid $gray-400;
}

.custom-control-label {
  margin-left: 10px;
}

.chat-main-cards-borders {
  border: 1px solid rgba($gray-600, 0.5) !important;
  border-radius: 3.25px !important;
}

.chats-list-scrollbar {
  height: 60vh;
  @media (max-height: 568px) {
    height: 41.5vh !important;
  }
  @media (max-height: 650px) {
    height: 49vh;
  }
  @media (min-width: 768px) {
    height: 65vh;
  }
  @media (min-width: 1200px) {
    height: 499px;
  }
}

.user-list-scrollbar {
  @media (max-height: 568px) {
    height: 20vh !important;
  }
  @media (max-height: 650px) {
    height: 30vh;
  }
  @media (min-width: 768px) {
    height: 53vh;
  }
  @media (min-width: 1200px) {
    height: 377px;
  }
  height: 45vh;
}

.feed-scrollbar {
  height: 56.5vh;
  @media (max-height: 568px) {
    height: 38.5vh !important;
  }
  @media (max-height: 650px) {
    height: 44.5vh;
  }
  @media (min-width: 768px) {
    height: 62.5vh;
  }
  @media (min-width: 1200px) {
    height: 470px;
  }
}

.shareable-cart-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: calc(100vh - 154px);
}

.inner-border {
  &.left {
    border-left: 1px solid rgba($gray-400, 0.3);
  }
  &.right {
    border-right: 1px solid rgba($gray-400, 0.3);
  }
  &.lg-right {
    @media (min-width: 1200px) {
      border-right: 1px solid rgba($gray-400, 0.3);
    }
  }
}
