.pro-menu {
  height: 100%;

  ul {
    height: 100%;
  }
}

.pro-sidebar-footer {
  border-top: none !important;
  // position: absolute;
  bottom: 0px;
  width: 100%;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  transition: transform 0.5s ease 0s;
  // margin-top: 48px;

  &.sidebar-open {
    justify-content: flex-end;
    i {
      transform: rotate(180deg);
    }
  }

  :hover {
    i {
      color: $white;
    }
  }
  button {
    i {
      margin-right: 8px;
      font-size: 18px;
      color: $sidebar-text-color;
    }
  }
}

.pro-sidebar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 50px;
  height: 50px;

  border: none !important;
  padding-right: 24px;
}

.pro-sidebar {
  position: fixed;
  top: 0;
  // height: 100vh;

  width: $sidebar-width;

  ::-webkit-scrollbar {
    display: none;
  }

  &.collapsed {
    width: $sidebar-collapsed-width !important;
  }

  @media (max-width: 768px) {
    width: 100vw !important;
  }

  @media (max-width: 1199px) {
    width: $sidebar-width;

    &.collapsed {
      min-width: 0px !important;
      width: 0px !important;
    }
  }

  &.consultant-sidebar {
    @media (min-width: 1200px) {
      width: 576px;
    }
    &.collapsed {
      @media (min-width: 1200px) {
        min-width: 0px !important;
        width: 0px !important;
      }
    }
    .pro-sidebar-inner {
      background-color: #2544b1 !important;
    }
  }
}

.pro-menu-item {
  height: 45px;
  .pro-inner-item {
    word-wrap: break-word;
    height: 45px;
    // padding: 8px 35px 8px 2px !important;
    // padding: 8px 0px 0px 8px !important;
    transition: all 0.5s ease 0s;
  }
  &.collapsed {
    .pro-inner-item {
      height: 45px;
    }
  }
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0px 0px 0px 0px !important;
}

.pro-item-content {
  .nav-link {
    height: 45px !important;
    font-weight: $font-weight-normal;
    span {
      color: $sidebar-text-color;
      font-size: 16px;
      // font-weight: $font-weight-normal;
    }
    i {
      color: $sidebar-text-color;
      font-size: 18px;
      margin-left: 8px;
      margin-right: 12px;
    }
    &:hover {
      span,
      i {
        color: $white;
      }
    }

    &.is-current-location {
      span {
        color: $white;
        font-weight: $font-weight-medium;
      }

      i {
        color: $white;
      }
    }
    padding: 0px 0px 0px 12px;
    // margin-right: 2px;
  }
}

.sidebar-footer-year {
  margin-top: 12px;
}

.sidebar-footer-email {
  text-decoration: underline;
  width: fit-content;
}

.sidebar-need-help-msg {
  position: absolute;
  left: 24px;
  color: white;
  font-size: 14px;
  white-space: pre;
  width: 100%;
  padding-bottom: 24px;

  @media screen and (orientation: landscape) {
    padding-top: 48px;
  }
}

@media (min-width: 1200px) {
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow: visible !important;
  }
}
