.fade-enter {
  opacity: 0.01;
  transform: scale(0.1, 1);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: all 500ms ease-in 500ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1, 1);
}

.fade-exit.fade-exit-active {
  z-index: 200;
  opacity: 0.01;
  transform: scale(0.1, 1);
  transition: all 500ms ease-in 300ms;
}

.search-fade {
  opacity: 0.01;
  transform: translateX(-80px) scaleX(0.8);
  transition: 500ms;

  &--open {
    opacity: 1;
    transform: translateX(0px) scaleX(1);
  }
}
