//
// _footer.scss
//

.footer {
  transition: all 0.5s ease 0s;
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: absolute;
  right: 0;
  color: $footer-color;
  margin-left: $sidebar-collapsed-width;
  height: $footer-height;
  background-color: $footer-bg;
  width: calc(100% - #{$sidebar-collapsed-width});
  @media (max-width: 1199px) {
    width: 100%;
  }
  transition: width, margin-left 0.5s ease 0s;

  &.sidebar-open {
    transition: all 0.5s ease 0s;
    width: calc(100% - #{$sidebar-width});
    margin-left: $sidebar-width;

    // margin-left: $sidebar-width;
    &.container-fluid {
      max-width: 100% !important;
    }
  }
}

.footer-shareable {
  transition: all 0.5s ease 0s;
  bottom: 0;
  width: 100%;
  position: absolute;
  right: 0;
  color: $footer-color;
  margin-left: $sidebar-collapsed-width;
  height: $footer-height;
  background-color: $footer-bg;
  @media (max-width: 1199px) {
    width: 100%;
  }
}

.grecaptcha-badge {
  z-index: 9999;
}

@media (max-width: 768px) {
  .mobile-footer {
    text-align: center !important;
  }
}

@media (max-width: 992px) {
  .footer {
    left: 0;
    margin-left: 0px;
  }
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;
  }
}

// body[data-layout='horizontal'] {
//   .footer {
//     left: 0 !important;
//   }
// }

.need-help-msg {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: $font-weight-normal;
  transition: all 0.5s ease 0s;
  .footer-email {
    text-decoration: underline;
    margin-left: 3px;
    cursor: pointer;
  }
  strong {
    margin-right: 3px;
  }
}

.year-Viddy {
  font-size: 14px;
  font-weight: $font-weight-normal;
}
