/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Custom Bootstrap Css File
*/

//Core files
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import './variables';
@import '../../../node_modules/bootstrap/scss/bootstrap';

@import './custom/components/reboot';
@import './custom/components/backgrounds';
@import './custom/components/badge';
@import './custom/components/buttons';
@import './custom/components/breadcrumb';
@import './custom/components/card';
@import './custom/components/dropdown';
@import './custom/components/nav';
@import './custom/components/table';
@import './custom/components/pagination';
@import './custom/components/progress';

.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 0.2 !important;
}
.opacity-2 {
  opacity: 0.4 !important;
}
.opacity-3 {
  opacity: 0.6 !important;
}
.opacity-4 {
  opacity: 0.8 !important;
}
.opacity-5 {
  opacity: 1 !important;
}

/* Maybe even support hover opacity shifts */
.opacity-0h5 {
  opacity: 0 !important;
  transition: opacity 0.25s ease-in-out !important;
  -moz-transition: opacity 0.25s ease-in-out !important;
  -webkit-transition: opacity 0.25s ease-in-out !important;
}
.opacity-0h5:hover {
  opacity: 1 !important;
}

@media (min-width: 768px) {
  .h-md-100 {
    height: 100% !important;
  }
}
