//
// Chat.scss
//

.chat-leftsidebar {
  @media (min-width: 992px) {
    min-width: 380px;
  }

  .chat-leftsidebar-nav {
    .nav {
      background-color: $card-bg;
    }

    .tab-content {
      min-height: 488px;
    }
  }
}

.dripicons-cross {
  color: #f7f7f7;
}
.dripicons-cross {
  color: #f7f7f7;
}

.dripicons-checkmark {
  color: #f7f7f7;
}

.fa-bell {
  // margin-left: 8px;
  // margin-top: 6px;
  display: block;
}

.notifications-bell-container {
  background-color: rgba($topbar-search-bg, 0.07) !important;
  // height: 38px;
  // width: 38px;
  // border-radius: 50%;
  // padding-left: 12px;
  // padding-top: 11px;

  // width: 38px;
}

.chat-btn {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 8px;
}

.user-selected-check {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'flex-end';
  margin-right: '10px';
}

.chat-notifications-btn {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: inherit;
  .chat-notifications {
    z-index: inherit;
    .notifications-bell-container {
      z-index: inherit;
      h6 {
        z-index: inherit;
        span {
          z-index: inherit;
        }
      }
    }
  }
  // margin-bottom: 8px;

  a {
    margin-top: 7px;
    // margin-right: 20px;
  }

  // outline: none;
  // padding-top: 8px;
  // padding-right: 30px;
  // position: relative;
  // border-radius: 100%;
  // height: 40px;
  // width: 40px;
  // margin-right: 20px;
  // background-color: rgba($topbar-search-bg, 0.07);
}

.group-creation-floating-btns {
  position: absolute;
  bottom: 150px;
  // margin-top: 50px;
  // left: 270px;
  left: 20vw;

  @media (max-width: 1920px) {
    left: 22vw;
  }
  @media (max-width: 1366px) {
    left: 20vw;
  }
  @media (max-width: 1024px) {
    left: 22vw;
  }
  @media (max-width: 800px) {
    left: 20vw;
  }
  @media (max-width: 450px) {
    left: 65vw;
  }
  height: 200px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // position: sticky;
  // bottom: 100px;
  // // margin-top: 50px;
  // margin-left: 250px;
  // height: 200px;
  // width: 50px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}

.group-creation-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  width: 100%;
}

.chat-search-box {
  .form-control {
    border: 0;
  }
}

.message {
  max-width: 25vw;
  // word-break: break-all;
  word-break: normal;
}

.message-content {
  text-align: left;
}

.dripicons-chevron-down {
  float: right;
  outline: none;
}

.popover {
  box-shadow: $box-shadow;
  max-width: 300px;
}

.notification-msg-content {
  max-width: 10vw;
  word-wrap: break-word;
}
.img-msg-wrapper {
  height: 150px;
}

.img-fluid {
  max-height: 110px;
}

.notifications {
  color: #ced4da;
}

.chat-profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.user-profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.no-notifications {
  color: #ced4da;
  // margin-right: 20px;
}

.chat-list {
  margin: 0;
  li {
    &.active {
      .chat-conversation-list {
        // background-color: $card-bg;
        // background-color: $gray-200;
        background-color: rgba($primary, 0.1);
        border-color: transparent;
        box-shadow: $box-shadow;
        cursor: pointer;
        outline: none;
        &.no-pointer {
          cursor: default;
        }
      }
    }
    .chat-conversation-list {
      display: block;
      padding: 10px 15px;
      color: $gray-600;
      transition: all 0.4s;
      border-top: 1px solid $border-color;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      &.no-pointer {
        cursor: default;
      }
      &:hover {
        // background-color: $card-bg;
        // background-color: $gray-200;
        background-color: rgba($primary, 0.1);
        border-color: transparent;
        box-shadow: $box-shadow;
        cursor: pointer;
        &.no-pointer {
          cursor: default;
        }
        outline: none;
      }
    }
  }
}

.disabled-button {
  pointer-events: none;
  opacity: 0.4;
}

.dropdown-options {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.user-chat-nav {
  .dropdown {
    .nav-btn {
      height: 40px;
      width: 40px;
      line-height: 40px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      background-color: $light;
      border-radius: 50%;
    }

    .dropdown-menu {
      box-shadow: $box-shadow;
      border: 1px solid $border-color;
    }
  }
}

.chat-conversation {
  li {
    clear: both;
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;

    .title {
      background-color: $card-bg;
      position: relative;
      z-index: 1;
      padding: 6px 24px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }
    .badge {
      font-size: 12px;
    }
  }
  .conversation-list {
    margin-bottom: 15px;
    display: inline-block;
    position: relative;

    .ctext-wrap {
      display: inline-block;
      padding: 5px 15px;
      background-color: rgba($primary, 0.1);
      border-radius: 8px 8px 8px 0px;

      .conversation-name {
        font-weight: $font-weight-semibold;
        color: $primary;
        margin-bottom: 4px;
      }
    }

    .dropdown {
      float: right;
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;
        @media (max-width: 575.98px) {
          display: none;
        }
      }

      .dropdown-menu {
        box-shadow: $box-shadow;
        border: 1px solid $border-color;
      }
    }

    .chat-time {
      font-size: 12px;
    }
  }

  .right {
    .conversation-list {
      float: right;
      .ctext-wrap {
        background-color: rgba($primary, 0.1);
        text-align: right;
        border-radius: 8px 8px 0px 8px;
      }
      .dropdown {
        float: left;

        .dropdown-menu {
          right: 0;
          left: auto !important;
        }
      }

      &.last-chat {
        .conversation-list {
          &:before {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }

  .last-chat {
    .conversation-list {
      &:before {
        content: '\F009';
        font-family: 'Material Design Icons';
        position: absolute;
        color: $primary;
        right: 0;
        bottom: 0;
        font-size: 16px;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }
  }
}

.chat-input-section {
  border-top: 1px solid $border-color;
}

.chat-input {
  border-radius: 4px;
  box-shadow: 2px 4px 4px rgba(109, 113, 136, 0.08);
  background-color: $white;
  border: none;
  padding-right: 24px;
}

.chat-input-links {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  li {
    a {
      font-size: 16px;
      line-height: 36px;
      padding: 0px 4px;
      display: inline-block;
    }
  }
}

.chat-send {
  @media (max-width: 575.98px) {
    min-width: auto;
  }
}
.no-more-old-msgs {
  color: gray;
  margin-bottom: 8px;
}

.no-more-old-chats {
  color: gray;
  margin-left: 15px;
  word-wrap: break-word;
  // margin-bottom: 8px;
}

.create-new-group-text-btn {
  color: gray;
  margin-left: 4px;

  &.no-pointer {
    cursor: default;
  }
  // margin-bottom: 8px;
}

.finish-user-selection-btn {
  background-color: #27ae60;
  color: white;
  margin-left: 4px;
  // margin-bottom: 8px;
}

.select-more-users-warning {
  background-color: #e67e22;
  color: white;
  margin-left: 4px;
  // margin-bottom: 8px;
}

.no-more-users {
  color: gray;
  margin-left: 15px;
  word-wrap: break-word;
  // margin-bottom: 8px;
}

.load-old-msgs {
  margin-bottom: 8px;
  // background-color: #4CAF50;
  // border: none;
  // color: white;
  // padding: 20px;
  // text-align: center;
  // text-decoration: none;
  // display: inline-block;
  // font-size: 16px;
  // margin: 4px 2px;
  // border-radius: 50%;
}
