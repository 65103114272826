.notifications-badge {
  z-index: 200;
  // position: absolute;
  top: 0%;
  right: 60%;
  width: auto;
  border-radius: 50%;

  &.dc {
    right: 2%;
  }
}
.unread-msgs-badge {
  z-index: 200;
  position: absolute;
  top: -5%;
  right: 0%;
  width: auto;
  border-radius: 50%;

  &.dc {
    right: 2%;
  }
}
