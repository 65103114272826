.sign {
  position: fixed;
  bottom: 30px;
}
.sign a {
  color: rgb(24, 21, 190);
  opacity: 0.8;
  text-decoration: none;
  font-weight: 300;
}

.option strong {
  font-size: 1.3rem;
}

.selection {
  width: 100%;
  max-width: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  cursor: pointer;
  outline: none;
}

.option label {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.option__item {
  border-radius: 5px;
  margin: 0 5px;
  color: #2544b1;
  border: 2px solid #2544b1;
  cursor: pointer;
  transition: 100ms;
  background-color: #f9f9f9;
  outline: #2544b1;
}

.option-icon {
  font-size: 32px;
  margin: 0 15px;
  display: block;
}

.option__item input[type='radio'] {
  display: none;
}

.option__item input:checked ~ .option {
  color: white;
  border: none;
  background-color: #2544b1;
  animation: fade 600ms linear;
  transition: 100ms;
  cursor: pointer;
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 576px) {
  .option {
    padding: 20px;
  }

  .selection {
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
  }

  .option__item + .option__item {
    margin-top: 8px;
  }
}
