//
// _table.scss
//

.table {
  td {
    padding: 6px;
    font-size: 14px;
  }
  th {
    font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}
