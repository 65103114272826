//
// _header.scss
//

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: $header-bg;
  box-shadow: $box-shadow;
  overflow: hidden; /* Hide scrollbars */
}

#page-topbar-old {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: $header-bg;
  box-shadow: $box-shadow;
}

.las {
  font-size: 25px;
  &.la-cog {
    color: $input-color;
  }
  &.la-bell {
    color: $input-color;
    // font-size: 23px;
  }
}

.navbar-header-old {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;

  .dropdown.show {
    .header-item {
      background-color: $gray-100;
    }
  }
}

.navbar-header-new {
  // display: flex;
  // -ms-flex-pack: justify;
  // justify-content: space-between;
  // align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;

  .dropdown.show {
    .header-item {
      background-color: $gray-100;
    }
  }

  .row {
    .col {
      height: $header-height !important;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.user-options {
  .popover {
    margin-left: 60px;
    border-radius: 15px;
    .arrow {
      // margin-left: 120px;
    }
    .popover-inner {
      .popover-body {
        hr {
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }
      // margin-right: 200px;
    }
  }
}
.user-popover-option {
  width: 120px;
  font-size: 14px !important;
  font-weight: $font-weight-medium;

  &.my-profile {
    color: $black;
    margin-top: 5px;
  }

  &.logout {
    color: $danger;
    margin-bottom: 5px;
  }
}

.store-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // width: 100px;

  height: $header-height;
  // float: right;
}
.header-profile-menu {
  list-style-type: none;
  .nav-link {
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
}

.header-row {
  width: 100vw;
}

.header-profile-navlink {
  list-style: none;
  display: flex !important;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 15px;
    object-fit: cover;
  }
  span {
    margin-right: 15px;
    color: $header-item-color;
    font-size: 13px;
    font-weight: $font-weight-normal;
  }

  i {
    color: $header-item-color;
  }
}

.header-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // max-width: 100px !important;

  @media (max-width: 1199px) {
    // padding-right: 90px;
  }

  li {
    list-style: none;
  }

  &__toggle {
    button {
      i {
        color: $black;
      }
    }
    @media (max-width: 1199px) {
      width: 70vw;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      font-size: 20px;
    }
  }

  &__store-picker {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: auto;

    li {
      list-style: none;
    }

    .configurations {
      a {
        margin-top: 5px;
        // margin-left: 10px;
      }
    }
  }

  .header-profile-menu {
    width: auto;
  }

  div.btns-group-row.row {
    margin-right: 0px !important;
    // width: 50vw;
  }

  &__btns-group {
    @media (max-width: 1199px) {
      display: none;
    }
  }

  &.logo {
    width: 55px !important;
    // height: 36px !important;
    @media (max-width: 1199px) {
      // display: flex;
      // flex-direction: row;
      justify-content: flex-start;
      padding-left: 40px;

      width: 30vw;
      img {
        // float: left;
      }
    }
  }
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: $navbar-brand-box-width;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// .logo {
//   line-height: 70px;

//   .logo-sm {
//     display: none;
//   }
// }

.logo-light {
  display: none;
}

/* Search */

.app-search {
  padding: calc(#{$header-height - 38px} / 2) 0;

  .form-control {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    background-color: $topbar-search-bg;
    box-shadow: none;
    border-radius: 30px;
  }
  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;
    a {
      color: $dropdown-color;
    }
  }
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.page-content {
  padding: calc(#{$header-height} + #{$grid-gutter-width})
    calc(#{$grid-gutter-width} / 2) $footer-height
    calc(#{$grid-gutter-width} / 2);
}

@media (max-width: 320px) {
  .page-content.checkout-mobile-adjust {
    padding: 0;
  }
  .checkout-mobile-adjust {
    padding: 0 !important;
  }
  .checkout-mobile-adjust.btn-pix-details {
    padding: 0.75rem 0.5rem !important;
  }
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
  }
}

.notification-item {
  .media {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: $gray-300;
    }
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: $gray-600;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border-color: $gray-200;
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle='fullscreen'] {
    .bx-fullscreen::before {
      content: '\ea3f';
    }
  }
}

body[data-topbar='dark'] {
  #page-topbar {
    background-color: $header-dark-bg;
  }
  #page-topbar-old {
    background-color: $header-dark-bg-old;
  }

  @media (max-width: 1199px) {
    #page-topbar {
      position: absolute;
    }
  }

  .navbar-header-old {
    .dropdown.show {
      .header-item {
        background-color: rgba($white, 0.05);
      }
    }

    .waves-effect .waves-ripple {
      background: rgba($white, 0.4);
    }
  }

  .navbar-header-new {
    .dropdown.show {
      .header-item {
        background-color: rgba($white, 0.05);
      }
    }

    .waves-effect .waves-ripple {
      background: rgba($white, 0.4);
    }
  }

  .header-item {
    color: $header-dark-item-color;

    &:hover {
      color: $header-dark-item-color;
    }
  }

  .header-profile-user {
    background-color: rgba($white, 0.25);
  }

  .noti-icon {
    i {
      color: $header-dark-item-color;
    }
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }

  .app-search {
    .form-control {
      background-color: rgba($topbar-search-bg, 0.07);
      // background-color: $gray-500;
      color: $white;
    }
    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }
}

body[data-sidebar='dark'] {
  .navbar-brand-box {
    background: $sidebar-dark-bg;
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }
}

@media (max-width: 600px) {
  .navbar-header-old {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
  .navbar-header-new {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout='horizontal'] {
  .navbar-brand-box {
    width: auto;
  }
  .page-content {
    // margin-top: $header-height;
    width: 100% !important;
    padding: calc(70px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
      $footer-height calc(#{$grid-gutter-width} / 2);
    @media (max-width: 1199px) {
      // padding-top: 12px !important;
    }
  }
}

@media (max-width: 1199px) {
  body[data-layout='horizontal'] {
    .page-content {
      padding-top: $header-height;
    }
  }
}
